import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import Trustpilot from "../../images/svg/trustpilot.svg"
import Capterra from "../../images/svg/capterra.svg"
import Gcrowd from "../../images/svg/gcrowd.svg"
import ReviewCard1 from "../../components/cards/ReviewCard1"
import SectionText from "../../components/SectionText"

const Review1 = () => {
  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query {
      image1: allFile(filter: { relativePath: { eq: "review-avatar-1.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image2: allFile(filter: { relativePath: { eq: "review-avatar-2.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image3: allFile(filter: { relativePath: { eq: "review-avatar-3.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image4: allFile(filter: { relativePath: { eq: "review-avatar-4.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <Section className="">
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center tablet:gap-14 laptop:gap-[82px] gap-9">
            {/* top-part */}
            <div className="">
              {/* cards */}
              <div className="grid items-center justify-center grid-cols-1 gap-8 laptop:grid-cols-2">
                {/* card */}
                <ReviewCard1
                  imageData={image1.nodes[0]}
                  personName="Debbie R. Biehl"
                  personinfo="Founder, Alton"
                >
                  Globally myocardinate interactive supply chains with
                  distinctive quality vectors. Globally revolutionize global
                  sources through interoperable services.
                </ReviewCard1>
                <ReviewCard1
                  imageData={image2.nodes[0]}
                  personName="Markus Fox"
                  personinfo="Developer, LexCorp"
                >
                  Collaboratively administrate empowered markets via
                  plug-and-play networks. Dynamically procrastinate B2C users
                  after installed base benefits.
                </ReviewCard1>
                <ReviewCard1
                  imageData={image3.nodes[0]}
                  personName="Robert Machac"
                  personinfo="CEO at Foundry"
                >
                  Efficiently unleash cross-media information without
                  cross-media value. Quickly maximize timely deliverables for
                  real-time schemas.
                </ReviewCard1>
                <ReviewCard1
                  imageData={image4.nodes[0]}
                  personName="Daniel Joe"
                  personinfo="Designer at Xbiz"
                >
                  Uniquely matrix value through cooperative technology.
                  Competently parallel task fully researched data and enterprise
                  process improvements.
                </ReviewCard1>
              </div>
            </div>

            {/* bottom-part */}
            <div
              data-aos="fade-up"
              className="flex items-center flex-col justify-center mx-auto max-w-[448px] gap-6 tablet:gap-8"
            >
              <SectionText className="!pb-0">See all reviews on</SectionText>
              <div className="flex flex-wrap items-center justify-center gap-4 tablet:gap-12">
                <a href="https://www.capterra.com/" target="">
                  <Capterra></Capterra>
                </a>
                <a href="https://www.g2.com/">
                  <Gcrowd></Gcrowd>
                </a>
                <a href="https://www.trustpilot.com/">
                  <Trustpilot></Trustpilot>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Review1

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import ButtonOutline from "../../components/ButtonOutline"

const Hero4 = () => {
  return (
    <>
      <Section isHero className="relative overflow-hidden">
        <Container>
          {/* section-wrap */}
          <div className="flex justify-center flex-col items-center tablet:gap-20 gap-12 laptop:gap-[100px]">
            {/* top-part */}
            <div className="max-w-[800px] mx-auto">
              <SectionTitle isHero={true} className="text-center ">
                We make your business{" "}
                <span className="text text-primary-600">Profitable</span>
              </SectionTitle>
              <SectionText
                isHero={true}
                className="text-center mx-auto max-w-[648px]"
              >
                The Kloft platform enables you to make data-driven decisions and
                ensure the success of your product.
              </SectionText>
              <div className="flex items-center justify-center gap-5 button-wrap">
                <Link to="#">
                  <Button className="" size={"2xl"}>
                    Get started
                  </Button>
                </Link>
                <Link to="/pricing-2">
                  <ButtonOutline size={"2xl"}>See Pricing </ButtonOutline>
                </Link>
              </div>
            </div>
            {/* bottom-part */}
            <div className="">
              <StaticImage
                src="../../images/hero-4.png"
                alt=""
                className="relative z-20 rounded-3xl"
              />
            </div>
            {/* shape */}
            <div className="absolute -translate-x-1/2 -z-20 rotate-45  top-0 left-0 h-[456px] w-[456px] border-[120px] border-secondary-50 rounded-[80px]"></div>
            <div className="absolute translate-x-1/2 -z-30 right-0 bottom-0 h-[644px] w-[644px] border-[140px] border-neutral-50 rounded-full"></div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Hero4

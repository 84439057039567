import React from "react"
import { BarChartLine, Diagram3, PieChart } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import ServiceCard1 from "../../components/cards/ServiceCard1"

const Service1 = ({ className }) => {
  return (
    <>
      <Section
        className={`relative overflow-hidden bg-neutral-50 ${className}`}
      >
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center tablet:gap-14 gap-8 laptop:gap-[82px]">
            {/* top-part */}
            <div className="">
              <div className="flex flex-col items-center max-w-[586px] justify-center">
                <div data-aos="fade-in">
                  <SectionTitle className="text-center">
                    What we can do to{" "}
                    <span className="text text-primary-600 ">boost </span>
                    your business
                  </SectionTitle>
                </div>
              </div>
            </div>
            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 gap-8 tablet:grid-cols-2 laptop:grid-cols-3 tablet:gap-14 ">
                {/* card */}

                <ServiceCard1
                  title="Business Analysis"
                  text="Uniquely enable accurate supply chains rather than frictionless technology. Globally network focused materials."
                  icon={
                    <PieChart className="-translate-x-1/2 -translate-y-1/2" />
                  }
                  iconClassName="bg-secondary-200 text-neutral-600"
                  className="text-display-sm"
                ></ServiceCard1>

                <ServiceCard1
                  title="Financial Services"
                  text="Uniquely enable accurate supply chains rather than frictionless technology. Globally network focused materials."
                  icon={
                    <BarChartLine className="-translate-x-1/2 -translate-y-1/2" />
                  }
                  iconClassName="!bg-neutral-200 text-display-sm text-neutral-600"
                  className=" !bg-neutral-50"
                ></ServiceCard1>
                <ServiceCard1
                  title="Marketing Strategy"
                  text="Uniquely enable accurate supply chains rather than frictionless technology. Globally network focused materials."
                  icon={
                    <Diagram3 className="-translate-x-1/2 -translate-y-1/2" />
                  }
                  iconClassName="!bg-primary-200 text-display-sm text-neutral-600"
                  className=" !bg-primary-50"
                ></ServiceCard1>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Service1

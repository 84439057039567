import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SvgIcon from "../../images/svg/ster-icon-full.svg"
import TextSmall from "../TextSmall"

const ReviewCard1 = ({
  personName = "Debbie R. Biehl",
  personinfo = "Founder, Alton",
  children = " Globally myocardinate interactive supply chains with distinctive quality vectors. Globally revolutionize global sources through interoperable services.",
  className = "",
  imageData,
}) => {
  const image = getImage(imageData)
  return (
    <>
      <div
        data-aos="fade-up"
        className={`px-[52px] py-7 max-w-[460px] bg-white shadow-lg rounded-2xl flex flex-col justify-start items-start gap-8 ${className}`}
      >
        <div className="flex gap-[2px]">
          <SvgIcon className="" />
          <SvgIcon className="" />
          <SvgIcon className="" />
          <SvgIcon className="" />
          <SvgIcon className="" />
        </div>
        {/* text-and-person-info */}
        <div className="flex flex-col gap-6">
          <TextSmall className="!pb-0">{children}</TextSmall>
          <div className="flex gap-4">
            <GatsbyImage
              image={image}
              alt={personName}
              className="object-cover w-12 h-12 rounded-full"
            />
            <div className="flex flex-col gap-[2px]">
              <TextSmall className="!font-medium !text-neutral-700">
                {personName}
              </TextSmall>
              <p className="text-neutral-500 text-body-sm">{personinfo}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReviewCard1

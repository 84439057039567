import React from "react"
import { Link } from "gatsby"

import ButtonArrow from "../ButtonArrow"
import SectionText from "../SectionText"
import TextSmall from "../TextSmall"

const ServiceCard1 = ({
  title = "Business Analysis ",
  text = "Uniquely enable accurate supply chains rather than frictionless technology. Globally network focused materials.",
  icon = "",
  iconClassName = "",
  className = "",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className={`relative flex flex-col items-start justify-start gap-8 px-10 py-10 overflow-hidden rounded-2xl bg-secondary-50 shadow-lg ${className}`}
      >
        <div className="flex flex-col gap-4 max-w-[296px]">
          <SectionText
            isHero="false"
            className="!font-medium !text-neutral-700 !pb-0"
          >
            {title}
          </SectionText>
          <TextSmall>{text}</TextSmall>
        </div>
        <Link className="inline-block !pr-2" to="#">
          <ButtonArrow>Learn more</ButtonArrow>
        </Link>
        <div
          className={`h-[156px] flex justify-center items-center  z-10 absolute right-0 bottom-0 translate-x-1/3 translate-y-1/3 w-[156px] bg-secondary-200 rounded-full ${iconClassName}`}
        >
          {icon}
        </div>
      </div>
    </>
  )
}
export default ServiceCard1

import React from "react"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import SubscribeForm from "../../components/forms/SubscribeForm"

const Newsletter1 = () => {
  return (
    <>
      <Section className="overflow-hidden bg-primary-25">
        <Container>
          {/* section-wrap */}
          <div className="relative max-w-[1000px] mx-auto">
            <div
              data-aos="zoom-in"
              className="flex flex-col items-center relative z-30 justify-center px-5 tablet:px-10 py-5 laptop:px-[80px] tablet:py-10 laptop:py-[80px] bg-primary-100 rounded-3xl rounded-tr-[0px]"
            >
              <SectionTitle className="!text-center max-w-[642px]">
                {" "}
                Subscribe to get special{" "}
                <span className="text text-primary-600">updates</span>
              </SectionTitle>
              <SectionText className="tablet:w-[680px] tablet:px-10 text-center">
                We’ve over 20 years of experience, if you’re looking to revive
                the brands your team has shared, or your putting together a new
                virtual team we’re here to help
              </SectionText>
              <SubscribeForm className="w-[100%] tablet:w-[70%] laptop:w-[50%]" />
            </div>
            <div className="absolute top-0 right-0 z-20 translate-x-full -translate-y-full">
              <div
                data-aos="fade-down-left"
                className=" bg-primary-50 w-[80px] h-[80px] "
              ></div>
            </div>
            <div className="absolute bottom-0 left-0 -translate-x-1/2 translate-y-1/2">
              <div
                data-aos="fade-up-right"
                data-aos-delay="400"
                className="tablet:h-[100px] w-16 h-16 tablet:w-[100px] bg-neutral-100 "
              ></div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Newsletter1

import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero4 from "../sections/heroes/Hero4"
import Service1 from "../sections/services/Service1"
import About4 from "../sections/abouts/About4"
import Content1 from "../sections/contents/Content1"
import Review1 from "../sections/reviews/Review1"
import Contact2 from "../sections/contacts/Contact2"
import Faq2 from "../sections/faqs/Faq2"
import Newsletter1 from "../sections/newsletters/Newsletter1"

const HomePage4 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Home 04`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <Hero4 />
        <Service1 />
        <About4 />
        <Content1 />
        <Review1 />
        <Contact2 />
        <Faq2 />
        <Newsletter1 />
      </PageWrapper>
    </>
  )
}

export default HomePage4
